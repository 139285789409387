import { $axios } from "./index";
let api = {
  //get接口
  teamInfo(params){
    return $axios.get('/api/system/content/info',{params:{...params}})
  },
  teamList(params){
    return $axios.get('/api/system/team/list',{params:{...params}})
  },
  usInfo(params){
    return $axios.get('/api/system/us/info',{params:{...params}})
  },
  cooperationList(params){
    return $axios.get('/api/system/cooperation/list',{params:{...params}})
  },
  storyInfo(params){
    return $axios.get('/api/system/story/info',{params:{...params}})
  },
  advantageInfo(params){
    return $axios.get('/api/system/advantage/info',{params:{...params}})
  },
  companyInfo(params){
    return $axios.get('/api/system/company/info',{params:{...params}})
  },
  cataVideoInfo(params){
    return $axios.get('/api/system/product-focus/info',{params:{...params}})
  },
  bannerList(params){
    return $axios.get('/api/system/focus/list',{params:{...params}})
  },
  newsList(params){
    return $axios.get('/api/system/news/list',{params:{...params}})
  },
  newsInfo(id,params){
    return $axios.get(`/api/system/news/${id}`,{params:{...params}})
  },
  //产品
  serviceProductList(params){
    return $axios.get('/api/system/product/list',{params:{...params}})
  },
  serviceProductInfo(id,params){
    return $axios.get(`/api/system/product/${id}`,{params:{...params}})
  },
  // 服务领域
  serviceFieldList(params){
    return $axios.get('/api/system/field/list',{params:{...params}})
  },
  serviceFieldInfo(id,params){
    return $axios.get(`/api/system/field/${id}`,{params:{...params}})
  },
  // 综合服务
  integrateServiceList(params){
    return $axios.get('/api/system/service/list',{params:{...params}})
  },
  integrateServiceInfo(id,params){
    return $axios.get(`/api/system/service/${id}`,{params:{...params}})
  },
  // 润达产研
  characteristicInfo(params){
    return $axios.get('/api/system/characteristic/info',{params:{...params}})
  },
  cataPicList(params){
    return $axios.get('/api/system/image/list',{params:{...params}})
  },
  cataPicInfo(id,params){
    return $axios.get(`/api/system/image/${id}`,{params:{...params}})
  },
  catalogList(params){
    return $axios.get('/api/system/catalog/list',{params:{...params}})
  },
  commonSystemInfo(params){
    return $axios.get('/api/system/common/info',{params:{...params}})
  },
  // 案例
  getCaseDetail(id,params){
    return $axios.get(`/api/system/info/${id}`,{params:{...params}})
  },
  getCaseList(params){
    return $axios.get('/api/system/info/list',{params:{...params}})
  },
  getCategoryList(params){
    return $axios.get('/api/system/category/list',{params:{...params}})
  },
  getPublicKey(params){
    return $axios.get('/api/publickey',{params:{...params}})
  },
  logout(params){
    return $axios.get('/api/logout',{params:{...params}})
  },
  // getCaseList(params){
  //   return $axios.get('/rd_boot_web/rdExampleInfo/queryPage',{params:{...params}})
  // },
  getCaseInfo(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/detail',{params:{...params}})
  },
  // 字典
  getDictionaryByCode(params){
    return $axios.get('/rd_boot_web/dictionary/getDictionaryByCode',{params:{...params}})
  },
  deleteCase(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/deleteById',{params:{...params}})
  },
  enableStatus(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/enableStatus',{params:{...params}})
  },
  // 文件预览
  fileOss(params){
    return $axios.get('/rd_boot_web/sys/oss/file/static',{params:{...params}})
  },
},
//post 
apiUrl = {
  updateCase:'/rd_boot_web/rdExampleInfo/insertOrUpdate',
  uploadFile:'/api/common/fileUpload/upload',
  webLogin:'/api/pc/system/login',
  addCategory:'/api/system/category/add',
  addCase:'/api/system/info/add',
  editCase:'/api/system/info/edit',
  deleteCase:'/api/system/info/delete',
  deleteCategory:'/api/system/category/delete',
  addCommonSystem:'api/system/common/add',
  addLogo:'/api/system/common/addLog',
  deleteLogo:'/api/system/common/deleteLog',
  addQrCode:'/api/system/common/addQrCode',
  deleteQrCode:'/api/system/common/deleteQrCode',
  addBanner:'/api/system/focus/add',
  deleteBanner:'/api/system/focus/delete',
  
  // 产研
  addCatalog:'/api/system/catalog/add',
  deleteCatalog:'/api/system/catalog/delete',
  addCataVideo:'/api/system/product-focus/add',
  deleteCataVideo:'api/system/product-focus/delete',
  // 图片
  addCataPic:'/api/system/image/add',
  deleteCataPic:'/api/system/image/delete',
  // 系统特点
  saveCharacteristic:'/api/system/characteristic/add',
  // 综合服务
  addIntegrateService:'/api/system/service/add',
  editIntegrateService:'/api/system/service/edit',
  deleteIntegrateService:'/api/system/service/delete',
  // 服务领域
  addServiceFiled:'/api/system/field/add',
  editServiceFiled:'/api/system/field/edit',
  deleteServiceFiled:'/api/system/field/delete',
  // 服务领域产品
  addServiceProduct:'/api/system/product/add',
  editServiceProduct:'/api/system/product/edit',
  deleteServiceProduct:'/api/system/product/delete',

  // 新闻
  addNews:'/api/system/news/add',
  editNews:'/api/system/news/edit',
  deleteNews:'/api/system/news/delete',
  // 公司介绍
  addCompany:'/api/system/company/add',
  addAdvantage:'/api/system/advantage/add',
  addStory:'/api/system/story/add',
  //  合作生态
  addSystemCoop:'/api/system/cooperation/addSystemCoop',
  addCustomCoop:'/api/system/cooperation/addCustomCoop',
  addHonorCoop:'/api/system/cooperation/addHonorCoop',
  deletecooperation:'/api/system/cooperation/delete',
  addUs:'/api/system/us/add',
  // 团队
  oneTeam:'/api/system/team/one',
  twoTeam:'/api/system/team/two',
  threeTeam:'/api/system/team/three',
  deleteTeam:'/api/system/team/delete',
  addTeamContent:'/api/system/content/add'
  

}
for(let [key,value] of Object.entries(apiUrl)){
   api[key] = (params) => {
    return $axios.post(value,params)
   }
}
export default api