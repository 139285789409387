import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/',
    component: () => import('../views/layout.vue'),
    redirect:'/login',
    children:[
      
      // {
      //   path:'manage/case',
      //   component: () => import("../views/manage/page.vue"),
      // },
      // {
      //   path:'manage/addCase',
      //   component: () => import("../views/manage/addCase.vue"),
      //   meta:{
      //     NoPageName:true
      //   },
      // },
      {
        path: 'common/imgUpload',
        component: () => import('../views/common/imgUpload.vue'),
      },
      {
        path: 'common/form',
        component: () => import('../views/common/form.vue'),
      },
      {
        path: 'development',
        component: () => import('../views/development/index.vue'),
      },
      {
        path: 'service',
        component: () => import('../views/service/index.vue'),
      },
      {
        path: 'service/domain',
        component: () => import('../views/service/domain.vue'),
      },
      {
        path: 'service/product',
        component: () => import('../views/service/product.vue'),
      },
      {
        path: 'integrate',
        component: () => import('../views/integrate/index.vue'),
      },
      {
        path:'cases/list',
        component: () => import("../views/cases/index.vue"),
      },
      {
        path:'cases/addCase',
        component: () => import("../views/cases/add.vue"),
        meta:{
          NoPageName:true
        },
      },
      {
        path:'cases/detail',
        component: () => import("../views/cases/detail.vue"),
        meta:{
          NoPageName:true
        },
      },
      {
        path: 'news',
        component: () => import('../views/news/index.vue'),
      },
      {
        path: 'about',
        component: () => import('../views/about/index.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
