import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/index.less";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import api from './api/api'

const app= createApp(App)
app.config.globalProperties.$api = api
app.use(router).use(Antd).mount('#app')
